import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/FAQ/FaqAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/Testmonial-Grid/index.tsx");
